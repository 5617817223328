import React, { Fragment, forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Menu, MenuItem, TableCell, TableRow } from "@mui/material";

import pathnames from "routes/pathnames";

import api from "services/api";
import getWorkCompletionListing from "services/get-work-completion-listing";
import getWorkInspectionListing from "services/get-work-inspection-listing";
import getUnassignedSalesOrderListing from "services/get-unassigned-sales-order-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import sanitizeObject from "common/sanitize-object";
import capitalizeCharacter from "common/capitalize-character";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";
import formatCurrencyPattern, { formatCurrency } from "common/format-currency-pattern";

import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppCreateQuotationModal from "components/pages/sales/app-create-quotation-modal";
import AppSalesOrderAddItemModal from "components/pages/sales/app-sales-order-add-item-modal";
import AppQuotationDeleteItemModal from "components/pages/sales/app-quotation-delete-item-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";
import chevronIcon from "assets/images/chevron-right-light-blue-icon.svg";

const AppSalesOrderDetails = (props, ref) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [selectedRowItem, setSelectedRowItem] = useState(null);
	const [rowsExpanded, setRowsExpanded] = useState([]);
	const [itemTableAnchor, setItemTableAnchor] = useState(null);
	const [subItemTableAnchor, setSubItemTableAnchor] = useState(null);
	const selectedItem = useRef();
	const selectedSubItem = useRef();
	const quotationAddItemModalRef = useRef();
	const quotationDeleteItemModalRef = useRef();
	const quotationCreateModalRef = useRef();
	const queryLineParams = useRef({ page: 0, size: 10 });
	const [linesTableData, setLinesTableData] = useState(convertPaginationTableData());

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			referenceNo: "",
			title: "",
			quotationId: "",
			quotationReferenceNo: "",
			status: "",
			customerName: "",
			picName: "",
			picPrefixMobileNumber: "",
			picPostfixMobileNumber: "",
			picEmail: "",
			addressLine1: "",
			addressLine2: "",
			postcode: "",
			city: "",
			state: "",
			internalNote: "",
			customerNote: "",
			subtotalAmount: "",
			taxPercent: "",
			taxAmount: "",
			discountAmount: "",
			totalAmount: "",
			bankName: "",
			bankAccountName: "",
			bankAccountNumber: "",
			bankBranch: "",
			lineItems: [],
			workCompletionId: "",
			workInspectionId: "",
			workOrderId: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			title: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const onHandleGetCustomerId = (event) => {
		const customerId = event.target.value;

		formik.setValues({
			...formik.values,
			customerId: customerId
		});
	};

	let workInspectionKeyword = useRef();
	let workOrderKeyword = useRef();
	let workCompletionKeyword = useRef();
	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const onHandleGetSalesOrderItems = useCallback(async (uniqueId) => {
		let response = null;

		const params = sanitizeObject({ ...queryLineParams.current, "item-id": uniqueId });

		try {
			response = await api.get.salesOrders.itemListing(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setLinesTableData(obj);

			return response?.content || [];
		}
	}, []);

	const isWorkOrderDisabled = useMemo(() => {
		switch (formik.values.status) {
			case STATUS.DRAFT:
			case STATUS.DRAFT_WITH_EXCEPTION:
				return false;
			default:
				return true;
		}
	}, [formik.values.status]);

	const isWorkInspectionDisabled = useMemo(() => formik.values.status !== STATUS.DRAFT_WITH_EXCEPTION, [formik.values.status]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		const payload = {
			id: uniqueId
		};

		let response = null;

		try {
			response = await api.get.salesOrders.salesOrder(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const lineItems = await onHandleGetSalesOrderItems(uniqueId);

			memoSetFormValues({
				id: response?.id || "",
				city: response?.city || "",
				title: response?.title || "",
				state: response?.state || "",
				picName: response?.picName || "",
				picEmail: response?.picEmail || "",
				postcode: response?.postcode || "",
				bankName: response?.bankName || "",
				taxAmount: response?.taxAmount || 0,
				taxPercent: response?.taxPercent || 0,
				bankBranch: response?.bankBranch || "",
				referenceNo: response?.referenceNo || "",
				quotationId: response?.quotationId || "",
				totalAmount: response?.totalAmount || 0,
				customerName: response?.customerName || "",
				addressLine1: response?.addressLine1 || "",
				addressLine2: response?.addressLine2 || "",
				customerNote: response?.customerNote || "",
				internalNote: response?.internalNote || "",
				subtotalAmount: response?.subtotalAmount || 0,
				discountAmount: response?.discountAmount || 0,
				bankAccountName: response?.bankAccountName || "",
				bankAccountNumber: response?.bankAccountNumber || "",
				quotationReferenceNo: response?.quotationReferenceNo || "",
				picPrefixMobileNumber: response?.picPrefixMobileNumber || "",
				picPostfixMobileNumber: response?.picPostfixMobileNumber || "",
				lineItems: lineItems || [],
				status: response?.status || "",
				workInspectionId: response?.workInspection?.id || "",
				workCompletionId: response?.workCompletion?.id || "",
				workOrderId: response?.workOrder?.id || "",
			});

			workInspectionKeyword.current = response?.workInspection?.referenceNo;
			workOrderKeyword.current = response?.workOrder?.referenceNo;
			workCompletionKeyword.current = response?.workCompletion?.referenceNo;
		}
	}, [memoSetFormValues, onHandleGetSalesOrderItems]);

	const isDraft = useMemo(() => formik.values.status === STATUS.DRAFT, [formik]);
	const isCompletedDropOff = useMemo(() => formik.values.status === STATUS.COMPLETED || formik.values.status === STATUS.COMPLETED_WITH_EXCEPTION || formik.values.status === STATUS.DROP_OFF, [formik]);

	//prettier-ignore
	const onHandleGetItemsDetails = useCallback(async (uniqueId) => {
		const params = sanitizeObject({ ...queryLineParams.current, "item-id": uniqueId });

		let response = null;

		try {
			response = await api.get.salesOrders.itemListing(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setLinesTableData(obj);

			memoSetFormValues((prevValues) => ({ ...prevValues, lineItems: response.content }));
		}
	}, [memoSetFormValues]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.sales.salesOrders);
	}, [navigate]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: id,
				createFlag: !!values.createFlag,
				title: values.title,
				internalNote: values.internalNote,
				bankName: values.bankName,
				bankAccountName: values.bankAccountName,
				bankAccountNumber: values.bankAccountNumber,
				bankBranch: values.bankBranch,
				workInspectionId: values.workInspectionId,
  				workOrderId: values.workOrderId
			};

			response = await api.post.salesOrders.update(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (values.createFlag) {
				dispatch(promptLayoutAlertMessage({ message: "Sales Order was created successfully!" }));

				onHandleBack();
			} else {
				onHandleGetDetails(id);

				dispatch(promptLayoutAlertMessage({ message: "Draft was created successfully!" }));
			}
		}
	}, [dispatch, formik, id, onHandleBack, onHandleGetDetails]);

	//prettier-ignore
	const onHandleSubCellSelect = useCallback((rowIndex, index) => {
		const item = formik.values.lineItems[rowIndex].lineSubItems[index];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	const onHandleExpandCell = useCallback((index) => {
		setRowsExpanded((prev) => (prev.includes(index) ? [] : [index]));
	}, []);

	const processLineItems = useCallback((lineItems) => {
		return lineItems.map((item, index) => ({
			number: index + 1,
			id: item.id,
			title: item.title,
			configServiceRefNo: item?.configService?.referenceNo,
			referenceNo: item.referenceNo,
			serviceId: item.serviceId,
			serviceType: item.serviceType,
			description: item.description,
			quantity: item.quantity,
			unitName: item.unitName,
			unitPrice: item.unitPrice,
			lineItemTotalAmount: item.lineItemTotalAmount,
			lineSubItems: item.lineSubItems
		}));
	}, []);

	const onHandleCloseMenu = useCallback(() => {
		setItemTableAnchor(false);
		setSubItemTableAnchor(false);

		selectedItem.current = null;
		selectedSubItem.current = null;
	}, []);

	const onHandleAddQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		quotationAddItemModalRef.current.onHandleShow();
	}, []);

	const onHandleEditQuotationItem = useCallback(() => {
		setItemTableAnchor(false);
		setSubItemTableAnchor(false);

		const itemIndex = selectedItem.current.rowIndex;
		const subItemIndex = selectedSubItem.current;
		const itemToEdit = subItemIndex !== null ? selectedItem.current.lineSubItems[subItemIndex] : formik.values.lineItems[itemIndex];
		const itemId = linesTableData.content[itemIndex].id;
		const subItemId = linesTableData?.content[itemIndex]?.lineSubItems[subItemIndex]?.id;

		quotationAddItemModalRef.current.onHandleShowEdit(itemToEdit, itemIndex, subItemIndex, itemId, subItemId);
	}, [formik.values.lineItems, linesTableData]);

	const onHandleAddSubQuotationItem = useCallback(() => {
		setItemTableAnchor(false);

		let nextItems = [...formik.values.lineItems];

		const parentItemId = nextItems[selectedItem.current.rowIndex]?.id;

		quotationAddItemModalRef.current.onHandleShowSubItem(parentItemId);
	}, [formik.values.lineItems]);

	const onHandleConfirmRemoveQuotationItem = useCallback(() => {
		setItemTableAnchor(false);
		setSubItemTableAnchor(false);

		let payload = {};

		if (typeof selectedSubItem.current === "number") {
			payload = selectedItem.current.lineSubItems[selectedSubItem.current];
		} else {
			payload = selectedItem.current;
		}

		quotationDeleteItemModalRef.current.onHandleShow(payload);
	}, []);

	const onHandleRemoveQuotationItem = useCallback(async () => {
		let response = null;
		let nextItems = [...formik.values.lineItems];

		if (typeof selectedSubItem.current === "number") {
			const subItemId = nextItems[selectedItem.current.rowIndex].lineSubItems[selectedSubItem.current].id;

			if (subItemId) {
				let payload = {
					"subItem-id": subItemId
				};

				try {
					await api.post.salesOrders.removeSubItem(payload);

					response = true;
				} catch (error) {
					serveLayoutRequestErrors(error);
				}

				if (response) {
					dispatch(promptLayoutAlertMessage({ message: "Sub-Item was removed successfully!" }));
				}
			}

			nextItems[selectedItem.current.rowIndex].lineSubItems.splice(selectedSubItem.current, 1);
		} else {
			let response = null;

			const itemId = nextItems[selectedItem.current.rowIndex].id;

			if (itemId) {
				let payload = {
					"item-id": itemId
				};

				try {
					await api.post.salesOrders.removeItem(payload);

					response = true;
				} catch (error) {
					serveLayoutRequestErrors(error);
				}

				if (response) {
					dispatch(promptLayoutAlertMessage({ message: "Item was removed successfully!" }));
				}
			}

			nextItems = nextItems.filter((_, i) => i !== selectedItem.current.rowIndex);
		}

		formik.setFieldValue("lineItems", nextItems);

		onHandleCloseMenu();
	}, [onHandleCloseMenu, formik, dispatch]);

	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedItem.current = { ...tableMeta.tableData[tableMeta.rowIndex], rowIndex: tableMeta.rowIndex };

		selectedSubItem.current = null;

		setItemTableAnchor(event.currentTarget);
	}, []);

	const onToggleSubTableAction = useCallback((event, lineIndex, itemLineData, itemLineIndex) => {
		selectedItem.current = { ...itemLineData, rowIndex: itemLineIndex };

		selectedSubItem.current = lineIndex;

		setSubItemTableAnchor(event.currentTarget);
	}, []);

	const tableColumns = useMemo(() => {
		const columns = [
			{
				name: "number",
				label: "#",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						const item = formik.values?.lineItems[tableMeta.rowIndex];
						const expandedButtonClassNames = classNames({
							"table__expandable-button": true,
							"table__expandable-button--expanded": rowsExpanded.includes(tableMeta.rowIndex)
						});

						if (item?.lineSubItems?.length) {
							return (
								<div className="table__expandable">
									{value}

									<button type="button" className={expandedButtonClassNames} onClick={() => onHandleExpandCell(tableMeta.rowIndex)}>
										<div className="table__expandable-icon" />
									</button>
								</div>
							);
						}

						return value;
					}
				}
			},
			{
				name: "title",
				label: "Title",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "serviceId",
				label: "Service ID",
				options: {
					sort: true,
					sortThirdClickReset: true,
					customBodyRender: (value, tableMeta) => {
						const item = formik.values?.lineItems[tableMeta.rowIndex];

						return item?.referenceNo || item?.configService?.referenceNo;
					}
				}
			},
			{
				name: "quantity",
				label: "Quantity",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unitName",
				label: "Unit",
				options: {
					sort: true,
					sortThirdClickReset: true
				}
			},
			{
				name: "unitPrice",
				label: "Price Per Unit (MYR)",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value) => {
						return formatCurrency(value);
					}
				}
			},
			{
				name: "lineItemTotalAmount",
				label: "Amount",
				options: {
					sort: true,
					sortThirdClickReset: true,
					setCellHeaderProps: () => ({ className: "table__price" }),
					setCellProps: () => ({ className: "table__price" }),
					customBodyRender: (value) => {
						return formatCurrency(value);
					}
				}
			},
			{
				name: "action",
				label: "Action",
				options: {
					sort: false,
					customBodyRender: (value, tableMeta) => {
						const status = formik.values?.status;

						if (status !== STATUS.DRAFT_WITH_EXCEPTION) return;

						return (
							<button type="button" className="table__action" onClick={(event) => onToggleTableAction(event, tableMeta)}>
								<img src={moreIcon} alt="edit-icon" />
							</button>
						);
					}
				}
			}
		];

		return formik.values?.status === STATUS.DRAFT_WITH_EXCEPTION ? columns : columns.filter((column) => column.name !== "action");
	}, [formik.values?.lineItems, formik.values?.status, rowsExpanded, onHandleExpandCell, onToggleTableAction]);

	//prettier-ignore
	const onHandleRenderExpandedRow = useCallback((rowData, tableMeta) => {
		const itemLine = formik.values.lineItems?.[tableMeta.rowIndex];
		const items = formik.values.lineItems?.[tableMeta.rowIndex]?.lineSubItems;

		let rowIndex = tableMeta.rowIndex;

		return items?.map((o, i) => {
			return (
				<TableRow key={i} className="table__sub-item" onClick={() => onHandleSubCellSelect(rowIndex, i)}>
					<TableCell className="table__numbering">{rowIndex + 1 + "." + (i + 1) + "."}</TableCell>

					<TableCell>{o.title}</TableCell>

					<TableCell />

					<TableCell>{o.quantity}</TableCell>

					<TableCell>{o.unitName}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.unitPrice)}</TableCell>

					<TableCell className="table__price">{formatCurrency(o.lineItemTotalAmount || o.lineSubItemTotalAmount)}</TableCell>

					{formik.values.status === STATUS.DRAFT_WITH_EXCEPTION && (
						<TableCell>
							<button type="button" className="table__action" onClick={(event) => onToggleSubTableAction(event, i, itemLine, rowIndex)}>
								<img src={moreIcon} alt="edit-icon" />
							</button>
						</TableCell>
					)}
				</TableRow>
			);
		});
	}, [formik.values, onHandleSubCellSelect, onToggleSubTableAction]);

	const onHandleCreateQuotation = useCallback(async () => {
		let payload = { ...formik.values, createFlag: true };

		onHandleSubmit(payload);
	}, [formik.values, onHandleSubmit]);

	const calculateSubItemTotal = (subItem) => {
		const quantity = parseFloat(subItem.quantity) || 0;
		const unitPrice = parseFloat(subItem.unitPrice) || 0;
		const subItemTotal = quantity * unitPrice;

		return subItemTotal;
	};

	const calculateSubtotal = useCallback((lineItems) => {
		return lineItems.reduce((total, item) => {
			let itemTotal = 0;

			if (item.serviceType === "CHARGEABLE") {
				itemTotal += parseFloat(item.lineItemTotalAmount) || 0;
			}

			if (item.lineSubItems && item.lineSubItems.length > 0) {
				itemTotal += item.lineSubItems.reduce((subTotal, subItem) => {
					if (subItem.serviceType === "CHARGEABLE") {
						const subItemTotal = calculateSubItemTotal(subItem);

						return subTotal + subItemTotal;
					}

					return subTotal;
				}, 0);
			}

			return total + itemTotal;
		}, 0);
	}, []);

	//prettier-ignore
	const calculateTotals = useCallback((lineItems, taxPercent, discountAmount) => {
		const subtotalAmount = calculateSubtotal(lineItems);
		const taxAmount = (subtotalAmount * Number(taxPercent)) / 100;
		const totalAmount = subtotalAmount + taxAmount - (parseFloat(discountAmount) || 0);

		return {
			subtotalAmount: subtotalAmount.toFixed(2),
			taxAmount: taxAmount.toFixed(2),
			totalAmount: totalAmount.toFixed(2)
		};
	}, [calculateSubtotal]);

	//prettier-ignore
	const onHandleCellSelect = useCallback((cell, cellData) => {
		if (cell?.type) return;

		const item = formik.values.lineItems[cellData.rowIndex];

		if (item) setSelectedRowItem(item);
	}, [formik]);

	const emptyState = useMemo(() => {
		if (formik.values?.lineItems?.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Items Added.
							<span className="table__link" onClick={onHandleAddQuotationItem}>
								Add Items?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [formik.values.lineItems, tableColumns, onHandleAddQuotationItem]);

	const SelectedItemDetails = useCallback((obj) => {
		if (!obj.selectedRowItem) return null;

		const firstItem = obj.selectedRowItem.number === 1;
		const lastItem = obj.items.length === obj.selectedRowItem.number;

		let selected = obj.items.find((i) => i.id === obj.selectedRowItem.id);

		const onHandleNextItem = () => {
			setSelectedRowItem(obj.items[selected.number]);
		};

		const onHandlePrevItem = () => {
			setSelectedRowItem(obj.items[selected.number - 2]);
		};

		return (
			<div className="sales-order-details__item-table">
				<div className="item-table">
					<div className="item-table__content">
						<div className="item-table__item">
							<p className="item-table__label">Title</p>

							<p className="item-table__value">{obj.selectedRowItem.title}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service ID</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceId || obj.selectedRowItem?.referenceNo || obj.selectedRowItem?.configService?.referenceNo || obj.selectedItem?.configServiceRefNo}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Service Type</p>

							<p className="item-table__value">{obj.selectedRowItem.serviceType}</p>
						</div>

						<div className="item-table__item">
							<p className="item-table__label">Description</p>

							<p className="item-table__value">{obj.selectedRowItem.description}</p>
						</div>
					</div>

					<div className="item-table__pagination">
						<button type="button" className="item-table__button item-table__button--prev" disabled={firstItem} onClick={onHandlePrevItem}>
							<img src={chevronIcon} alt="pagination-prev" />
						</button>

						<p className="item-table__page">
							item of {selected?.number} of {obj.items.length}
						</p>

						<button type="button" className="item-table__button item-table__button--next" disabled={lastItem} onClick={onHandleNextItem}>
							<img src={chevronIcon} alt="pagination-next" />
						</button>
					</div>
				</div>
			</div>
		);
	}, []);

	//prettier-ignore
	const onHandleEditItem = useCallback((updatedItem, rowIndex, subItemIndex) => {
		const nextItems = [...formik.values.lineItems];

		if (subItemIndex !== null) {
			nextItems[rowIndex].lineSubItems[subItemIndex] = updatedItem;
		} else {
			nextItems[rowIndex] = { ...nextItems[rowIndex], ...updatedItem };
		}

		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(nextItems, formik.values.taxPercent, formik.values.discountAmount);

		formik.setValues({
			...formik.values,
			lineItems: nextItems,
			subtotalAmount,
			taxAmount,
			totalAmount
		});
	}, [formik, calculateTotals]);

	//prettier-ignore
	const onHandleCreateSubItem = useCallback((values) => {
		const mainItemIndex = selectedItem.current.rowIndex;
		const nextItems = [...formik.values.lineItems];

		if (!nextItems[mainItemIndex].lineSubItems) {
			nextItems[mainItemIndex].lineSubItems = [];
		}

		const currentSubItemCount = nextItems[mainItemIndex].lineSubItems.length;

		nextItems[mainItemIndex].lineSubItems.push(values);

		const { subtotalAmount, taxAmount, totalAmount } = calculateTotals(nextItems, formik.values.taxPercent, formik.values.discountAmount);

		formik.setValues({
			...formik.values,
			lineItems: nextItems,
			subtotalAmount,
			taxAmount,
			totalAmount
		});

		if (nextItems[mainItemIndex].lineSubItems.length > currentSubItemCount) {
			props.onHandleGetDetails(id);
		}
	}, [formik, calculateTotals, props, id]);

	//prettier-ignore
	const onHandleCreateItem = useCallback((values) => {
		const currentItemCount = formik.values.lineItems.length;
		const nextItems = [...formik.values.lineItems, values].map((o, i) => ({ number: i + 1, ...o }));
		const subtotalAmount = calculateSubtotal(nextItems);

		formik.setFieldValue("lineItems", nextItems);
		formik.setFieldValue("subtotalAmount", subtotalAmount.toFixed(2));

		if (nextItems.length > currentItemCount) {
			props.onHandleGetDetails(id);
		}
	}, [formik, calculateSubtotal, props, id]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		rowsExpanded: rowsExpanded,
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		count: linesTableData.totalElements,
		page: linesTableData.page,
		serverSide: true,
		renderExpandableRow: onHandleRenderExpandedRow,
		onCellClick: onHandleCellSelect,
		onTableChange: (action, tableState) => {
			if (action === "changePage") {
				queryLineParams.current.page = tableState.page;

				onHandleGetItemsDetails(id);
			}
		}
	}), [rowsExpanded, onHandleCellSelect, onHandleRenderExpandedRow, linesTableData.totalElements, linesTableData.page, onHandleGetItemsDetails, id]);

	const onHandleConfirmCreateSalesOrder = useCallback(async () => {
		const fieldErrors = await formik.validateForm();

		if (Object.keys(fieldErrors).length) {
			formik.setTouched(fieldErrors, true);

			return;
		}

		let payload = { ...formik.values, createFlag: true };

		onHandleSubmit(payload);
	}, [formik, onHandleSubmit]);

	useEffect(() => {
		if (id) {
			onHandleGetDetails(id);

			onHandleGetItemsDetails(id);
		}
	}, [id, onHandleGetDetails, onHandleGetItemsDetails, queryLineParams.current.page]);

	useEffect(() => {
		return () => {
			if (id) cancelRequest(ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER);

			if (id) cancelRequest(ENDPOINT_PATH.SALES_ORDERS.SALES_ORDER_ITEM);
		};
	}, [cancelRequest, id]);

	useImperativeHandle(ref, () => ({
		onHandleGetDetails: onHandleGetDetails
	}));

	return (
		<div className="app-sales-order-details">
			<div className="sales-order-details">
				<form className="sales-order-details__form" onSubmit={formik.handleSubmit}>
					<div className="sales-order-details__container">
						<div className="sales-order-details__wrapper sales-order-details__wrapper--divider">
							<p className="sales-order-details__label">General Details</p>

							<div className="sales-order-details__row sales-order-details__row--general-details">
								<AppInput disabled type="text" name="referenceNo" label="Sales Order ID" placeholder="Sales Order ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								<AppInput disabled name="status" label="Status" placeholder="Select..." value={capitalizeCharacter(formik.values.status.split("_").join(" "))} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

								<AppSelectInput pagination disabled={isWorkInspectionDisabled} name="workInspectionId" label="Work Inspection ID" placeholder="Select..." defaultSearchValue={workInspectionKeyword.current} loadOptions={getWorkInspectionListing} value={formik.values.workInspectionId} error={formik.errors.workInspectionId} touched={formik.touched.workInspectionId} onChange={formik.handleChange} />

								<AppSelectInput pagination disabled={isWorkOrderDisabled} searchable type="text" name="workOrderId" label="Work Order ID" placeholder="Select..." defaultSearchValue={workOrderKeyword.current} loadOptions={(payload) => getUnassignedSalesOrderListing({ salesOrderId: id, keyword: payload?.keyword })} value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />

								<AppSelectInput disabled pagination type="text" name="workCompletionId" label="Work Completion ID" placeholder="Select..." defaultSearchValue={workCompletionKeyword.current} loadOptions={getWorkCompletionListing} value={formik.values.workCompletionId} error={formik.errors.workCompletionId} touched={formik.touched.workCompletionId} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="quotationReferenceNo" label="Quotation ID" placeholder="Quotation ID" value={formik.values.quotationReferenceNo} error={formik.errors.quotationReferenceNo} touched={formik.touched.quotationReferenceNo} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="sales-order-details__wrapper">
							<p className="sales-order-details__label">Customer Details</p>

							<div className="sales-order-details__row">
								<AppInput type="text" disabled name="customerName" label="Registered Name" placeholder="Enter Registered Name" value={formik.values.customerName} error={formik.errors.customerName} touched={formik.touched.customerName} onChange={onHandleGetCustomerId} />

								<AppInput type="text" disabled name="picName" label="PIC Name" placeholder="Please input PIC name" value={formik.values.picName} error={formik.errors.picName} touched={formik.touched.picName} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								<AppMobileInput disabled type="number" name="picPostfixMobileNumber" label="Mobile No." value={formik.values.picPostfixMobileNumber} prefixNo={formik.values.picPrefixMobileNumber} error={formik.errors.picPostfixMobileNumber} touched={formik.touched.picPostfixMobileNumber} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

								<AppInput disabled type="text" name="picEmail" label="Email" placeholder="Please input picEmail" value={formik.values.picEmail} error={formik.errors.picEmail} touched={formik.touched.picEmail} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Please input address line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Please input address line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								<AppInput disabled type="text" name="state" label="State" placeholder="Select..." value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="city" label="City" placeholder="Select..." value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								<AppInput disabled type="text" name="postcode" label="Postcode" placeholder="Select..." value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					<div className="sales-order-details__container">
						<div className="sales-order-details__wrapper sales-order-details__wrapper--divider">
							<AppInput disabled={isCompletedDropOff} required type="text" name="title" label="Sales Order Title" placeholder="Please input sales order title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

							<div className="sales-order-details__table-header">
								<p className="sales-order-details__label">Item Lines</p>

								{!isCompletedDropOff && !isDraft && <AppButton outline type="button" label="Add" icon={addIcon} onClick={onHandleAddQuotationItem} />}
							</div>

							<AppTable data={processLineItems(formik.values.lineItems)} columns={tableColumns} options={tableOptions} components={emptyState} />

							<SelectedItemDetails selectedRowItem={selectedRowItem} items={processLineItems(formik.values.lineItems)} />

							<div className="sales-order-details__row">
								<AppInput disabled={isCompletedDropOff} multiline type="textarea" maxLength={1000} name="internalNote" label="Internal Note" placeholder="Enter Internal Note" value={formik.values.internalNote} error={formik.errors.internalNote} touched={formik.touched.internalNote} onChange={formik.handleChange} />

								<div className="sales-order-details__column sales-order-details__column--divider">
									<AppInput disabled type="text" name="subtotalAmount" label="Subtotal (MYR)" placeholder="For example: 100.00" value={formik.values.subtotalAmount} error={formik.errors.subtotalAmount} touched={formik.touched.subtotalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<div className="sales-order-details__gap">
										<AppInput disabled type="number" maxLength={1} name="taxPercent" label="Tax (%)" placeholder="Enter Tax(%)" value={formik.values.taxPercent} error={formik.errors.taxPercent} touched={formik.touched.taxPercent} onChange={formik.handleChange} />

										<AppInput disabled type="text" name="taxAmount" value={formik.values.taxAmount} touched={formik.touched.taxAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
									</div>
								</div>
							</div>

							<div className="sales-order-details__row">
								<AppInput disabled multiline type="textarea" maxLength={1000} name="customerNote" label="Customer Note" value={formik.values.customerNote} error={formik.errors.customerNote} touched={formik.touched.customerNote} onChange={formik.handleChange} />

								<div className="sales-order-details__column sales-order-details__column--divider">
									<AppInput disabled type="text" name="discountAmount" label="Discount (MYR)" value={formik.values.discountAmount} error={formik.errors.discountAmount} touched={formik.touched.discountAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

									<AppInput disabled type="text" name="totalAmount" label="Grand Total (MYR)" value={formik.values.totalAmount} error={formik.errors.totalAmount} touched={formik.touched.totalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
								</div>
							</div>
						</div>

						<div className="sales-order-details__wrapper">
							<p className="sales-order-details__label">Bank Details</p>

							<div className="sales-order-details__row">
								<AppInput disabled={isCompletedDropOff} type="text" name="bankName" label="Bank Name" placeholder="Enter Bank Name" value={formik.values.bankName} error={formik.errors.bankName} touched={formik.touched.bankName} onChange={formik.handleChange} />

								<AppInput disabled={isCompletedDropOff} type="text" name="bankAccountNumber" label="Account Number" placeholder="Enter Account Name" value={formik.values.bankAccountNumber} error={formik.errors.bankAccountNumber} touched={formik.touched.bankAccountNumber} onChange={formik.handleChange} />
							</div>

							<div className="sales-order-details__row">
								<AppInput disabled={isCompletedDropOff} type="text" name="bankAccountName" label="Account Name" placeholder="Enter Account Name" value={formik.values.bankAccountName} error={formik.errors.bankAccountName} touched={formik.touched.bankAccountName} onChange={formik.handleChange} />

								<AppInput disabled={isCompletedDropOff} type="text" name="bankBranch" label="Branch" placeholder="Enter Branch" value={formik.values.bankBranch} error={formik.errors.bankBranch} touched={formik.touched.bankBranch} onChange={formik.handleChange} />
							</div>
						</div>
					</div>

					{formik.values.preparedBy?.fullName && (
						<div className="sales-order-details__container sales-order-details__container--signature">
							<div className="sales-order-details__row sales-order-details__row--signature">
								<div className="sales-order-details__column">
									<AppInput disabled name="preparedBy.fullName" label="Prepared By" value={formik.values.preparedBy?.fullName} touched={formik.touched.preparedBy?.fullName} onChange={formik.handleChange} />

									<AppInputDragAndDrop disabled name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} />
								</div>
							</div>
						</div>
					)}

					<div className="sales-order-details__button-container">
						<AppButton outline className="sales-order-details__cancel-button" type="button" label="Cancel" onClick={onHandleBack} />

						{!isCompletedDropOff && (
							<Fragment>
								<AppButton outline type="submit" label="Update Draft" />

								<AppButton type="button" label="Create Sales Order" disabled={formik.isSubmitting} onClick={onHandleConfirmCreateSalesOrder} />
							</Fragment>
						)}
					</div>
				</form>
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "sales-order-create-edit-table-menu" }} anchorEl={itemTableAnchor || subItemTableAnchor} open={!!itemTableAnchor || !!subItemTableAnchor} onClose={onHandleCloseMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditQuotationItem}><img src={editIcon} alt="inventory-edit" />Edit</MenuItem>

				{itemTableAnchor && <MenuItem onClick={onHandleAddSubQuotationItem}><img src={addIcon} alt="inventory-close" />Add Sub-Item</MenuItem>}

				<MenuItem onClick={onHandleConfirmRemoveQuotationItem}><img src={trashIcon} alt="inventory-transfer" />Remove</MenuItem>
			</Menu>

			<AppSalesOrderAddItemModal ref={quotationAddItemModalRef} onConfirm={onHandleCreateItem} onConfirmSubItem={onHandleCreateSubItem} onEdit={onHandleEditItem} quotationId={formik.values.quotationId} />

			<AppQuotationDeleteItemModal ref={quotationDeleteItemModalRef} onConfirm={onHandleRemoveQuotationItem} />

			<AppCreateQuotationModal ref={quotationCreateModalRef} onConfirm={onHandleCreateQuotation} />
		</div>
	);
};

export default memo(forwardRef(AppSalesOrderDetails));
